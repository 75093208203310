.modalBox {

  .modalButtonsDivider {
    margin: 20px -30px;
  }

  .modalButtons {
    display: flex;
    gap: 20px;
  }

}
