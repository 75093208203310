.attendanceClasses {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  &Item{
    cursor: pointer;
    padding: 13px 32px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;


    &--active {
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    &--saved {
      background-color: #66bb6a;
    }
  }
}
