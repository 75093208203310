.medical {

  .attachmentsListContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .attachmentItem {
      display: flex;
      gap: 20px;
      align-items: center;

      color:  #3064BA;
      font-size: 16px;
      font-weight: bold;
    }

    .attachmentDelete {
      cursor: pointer;
    }
  }


}

.addAttachmentBtn {
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 18px;
}
