.children {
  margin: auto;


  &Filters {
    display: grid;
    grid-template-columns: 200px 200px 200px 200px;
    align-items: baseline;
    gap: 20px;
    margin-top: 10px;
  }

  &FiltersButtons {
    margin-top: 20px;

  }

  &List {
    .minWidthCell {
      min-width: 200px;
    }
  }

  @media all and (max-width: 600px){
    &Filters {
      grid-template-columns: 200px 200px;
    }
  }
}



