.child {
  max-width: 600px;
  margin: auto;

  &Transfer {
    display: grid;
    grid-template-columns: 250px 160px 120px;
    align-items: center;
    gap: 10px;
  }

  &Form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
  }

  @media all and (max-width: 425px) {
    &Transfer {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      gap: 10px;
    }

    &Form {
      grid-template-columns: 1fr;
      align-items: start;
    }
  }
}
