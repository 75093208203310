.roasters {
  .filters {
    display: grid;
    grid-template-columns: 200px 200px 200px 100px 100px;
    align-items: baseline;
    gap: 20px;

    &Selector {
      min-width: 130px;
    }
  }
}

@media all and (max-width: 768px){
  .roasters {
    .filters {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 100px 100px;
      align-items: baseline;
      gap: 20px;

    }
  }
}

@media all and (max-width: 480px){
  .roasters {
    .filters {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: baseline;
      gap: 20px;

    }
  }
}
