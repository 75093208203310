body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

a {
  color: rgb(21, 101, 192);

  &:hover {
    color: rgb(30, 136, 229);
  }
}

.container500 {
  max-width: 500px;
  margin: auto;
}

.programsFilter {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.programs {
  .programForm {
    display: flex;
    align-items: baseline;
    gap: 30px;
  }

  .programSchoolGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.alert {
  padding: 10px 20px;
  border-radius: 5px;

  &--success {
    background-color: #66bb6a;
    color: white;
  }

  &--error {
    background-color: #ef5350;
    color: white;
  }

  &--warning {
    background-color: #ffa726;
    color: white;
  }
}

.text {
  &--center {
    text-align: center;
  }

  &--success {
    color: #66bb6a;
  }

  &--error {
    color: #ef5350;
  }

  &--warning {
    color: #ffa726;
  }
}


.transactions {
  .filters {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 200px 200px 200px 200px 100px 100px;
    gap: 20px;
    align-items: end;

    &_date {
      display: grid;
      grid-template-columns: 190px 190px;
      grid-column-gap: 20px;
    }
  }

  .paginationContainer {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media all and (max-width: 1024px){
  .transactions {
    .filters {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 10px;
    }
  }
}

@media all and (max-width: 480px){
  .transactions {
    .filters {
      grid-template-columns: 1fr 1fr;
    }
  }

  .programs {
    .programForm {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: 10px;
    }

    .programSchoolGrid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}


.invoice {
  .buttons_top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 20px;
  }

  .invoice_form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    input {
      font-size: 17px !important;
      font-weight: bolder !important;
    }
  }

  @media all and (max-width: 425px){
    .buttons_top {
      grid-template-columns: 1fr;
    }
  }
}

.parents {
  &_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_filter {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &_action_buttons {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  @media all and (max-width: 425px){
    &_actions {
      flex-direction: column;
      gap: 10px;
    }

    &_filter {
      gap: 10px;
      flex-wrap: wrap;
    }
  }
}

.contacts {
  &_filter {
    display: flex;
    align-items: baseline;
    gap: 20px;
  }
}

.all_children {
  &_filter {
    display: flex;
    align-items: baseline;
    gap: 20px;
  }
}

@import 'scss/all';
