::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.5);
}

.app-bar {
  justify-content: space-between;
}


.loginPaper {
  max-width: 400px;
  margin: 20px auto;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
}

.app-content{
  -moz-transition: padding-left 218ms cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: padding-left 218ms cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-transition: padding-left 218ms cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: padding-left 218ms cubic-bezier(0.4, 0.2, 0.2, 1);
  /*padding-right: 20px !important;*/
  padding-top: 20px !important;
}

.baseWellCome {
  display: inline;
  font-size: 14px;
  color: white;
}

.app-left-menu-link {
  color: white;
  text-decoration: none;
  padding: 16px 16px 16px 16px;
  position: relative;
  display: block;
}

.app-left-menu-link.level-2 {
  padding-left: 34px;
}

.app-left-menu-link.level-3 {
  padding-left: 54px;
}

.app-left-menu-link:hover {
  background-color: rgb(0, 0, 0, 0.1) !important;
}

.app-left-menu-link.active {
  background-color: rgb(21, 101, 192) !important;
}

.app-left-menu a.active {
  background-color: rgb(21, 101, 192) !important;
}

.app-content.expanded {
  padding-left: 210px;
}

.app-title {
  margin: auto 0;
  color: white;
}

.invoicesSelectSchool {
  display: flex;
  align-items: center;
  gap: 20px;
}

.invoicesSelectSchool_label {
}

.invoicesStatusFilter {
  display: flex;
}

.invoicesDateFilter {
  margin-left: 20px;
  display: flex;
  gap: 30px;
  flex-direction: row;
  align-items: center;
}

.editInvoicesProgramsFilter {
  text-align: center;
}

.dashboardFilters {
  display: flex;
}

.MuiTableRow-head.fixed {
  position: sticky;
  z-index: 1;
  background-color: white;
}

.MuiTableRow-head.fixed th {
  background-color: rgb(21, 101, 192);
  color: white;
}


@media screen and (max-width: 1023px) {
  .baseWellCome, .app-title {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .dashboardFilters {
    flex-direction: column;
  }


  .editInvoicesProgramsFilter {
    text-align: left;
  }

  .invoicesSelectSchool {
    flex-direction: column;
    align-items: flex-start;
  }

  .invoicesStatusFilter {
    flex-direction: column;
  }

  .invoicesDateFilter {
    margin-left: 20px;
    flex-direction: column;
    align-items: start;
  }

  .app-content.expanded {
    padding-left: 0;
  }
}

.inner-div{
  padding-left: 50px;
}
