.attendance {
  padding-bottom: 50px;

  .tabsMenu {
    margin-bottom: 10px;
  }

  .classesMenu {
    display: flex;
    gap: 19px;
    flex-wrap: wrap;

    .classItem {
      padding: 13px 32px;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);


    }
  }

  .filters {
    display: grid;
    grid-template-columns: 200px 100px 100px 100px 300px;
    align-items: center;
    gap: 20px;
    margin-top: 10px;

    &--archive {
      grid-template-columns: 140px 200px 140px 200px 100px 350px;
      margin-top: 0;
    }

    &Item--grouped {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &Selector {
      min-width: 130px;
    }
  }

  .actionsContainer {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  &ArchiveContainer {
    margin-top: 20px;
  }
}

